<template>
  <main class="HubControlForm">
    <TheSubHeader 
      icon="address" 
      :title="TheSubHeaderTitle"
      page-title-tag="Hubs Form"
      :actions="['back']"
    />
    <div class="HubControlForm__content">
      <DynamicForm
        ref="form"
        class="HubControlForm__form"
        submit-type="json"
        :model="form"
        :blueprint="blueprint({isDropoff})"
        :endpoint-errors="endpointErrors"
        @showLightbox="showLightbox"
        @checkDeleteStore="checkStore"
        @setAddress="setAddress"
        @submit="onSubmit"
      />
    </div>
  </main>
</template>

<script>
import { routes } from '@/api'
import { mapActions } from 'vuex'
import { mapState } from 'vuex'
import { TheSubHeader } from '@/components/organisms'
import { DynamicForm } from '@/components/molecules'
import blueprint from './HubControlForm.blueprint'

export default {
  name: 'HubControlForm',
  components: {
    TheSubHeader,
    DynamicForm,
  },
  data() {
    return {
      form: {
        name: '',
        route_limit: '',
        schedule: '',
        zip: '',
        city: '',
        neighborhood: '',
        address: '',
        number: '',
        complement: '',
        state_id: null,
        store_id: [],
        transit_points_type_id: false,
        is_active: false,
        transit_point_delivery_id: null,
        driver_id: null,
        reception_user_id: null,
        route_name: ''
      },

      file: null,
      endpointErrors: {}
    }
  },
  computed: {
    ...mapState({
      states: state => state.address.states,
    }),
    
    getUser(){
      return localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))  : ''
    },

    transitPointId() {
      return this.$route.params.id
    },

    TheSubHeaderTitle() {
      return this.transitPointId ? 'Cadastro do HUB' : 'Cadastrar HUB'
    },

    parseStore(){
      let store = this.form.store_id.map(s => s.id)
      return store
    },

    isDropoff() {
      return this.form.transit_points_type_id
    },

    transitPointDeliveryId() {
      return this.isDropoff ? this.form.transit_point_delivery_id ? this.form.transit_point_delivery_id.id : null : null
    }

  },
  mounted() {
    if (this.transitPointId) {
      this.fetchTransitPoint()
    }
  },

  methods: {
    blueprint,
    ...mapActions({
      checkDeleteStore: 'drivers/checkDeleteStore',
      setLoading: 'loading/setLoading',
    }),

    async showLightbox(e, label) {
      this.modalTitle = label
      this.lightbox = e instanceof File ? await this.readFile(e) : e
    },
    
    setAddress(data) {
      this.form = {...this.form, ...data}
    },

    async checkStore(data){
      if (!this.driverId) return
      let res = await this.checkDeleteStore({driver_id: this.driverId, store_id: data.tag.id})
      if (res) {
        this.form.store = data.all.filter((v) => v.id !== data.tag.id)
      }else{
        this.form.store = data.all
        this.show = true
      }
      
    },

    async fetchTransitPoint(){
      this.setLoading(true)
      await routes.fetchTransitPoint({ id: this.transitPointId }, (res) => {
        var date = new Date()
        var resHour = res.schedule.toString()
        resHour = resHour.split(':')
        date.setHours(resHour[0])
        date.setMinutes(resHour[1])
        date.setSeconds(resHour[2])

        res = {
          ...res,
          ...res.address,
          schedule: date.getTime(),
          store_id: res.stores,
          transit_points_type_id: res.transit_points_type_id === 1 ? false : true,
          route_name: res?.route_info?.route_name
        }
        
        res.state_id = this.states.filter((state) => state.id === res.state_id)[0]
      
        for (let i in this.form) {
          if (res[i]) this.form[i] = res[i]
        }
      }, (e) => e)
      this.setLoading(false)
    },


    async updateTransitPoint(body) {
      this.setLoading(true)
      await routes.updateTransitPoint({ id: this.transitPointId, body }, () => this.$router.push('/routes/hub'), (e) => {this.endpointErrors = e.validation}, () => this.setLoading(false))
    },

    async createTransitPoint(body) {
      this.setLoading(true)
      await routes.createTransitPoint(body, () => {
        this.$router.push('/routes/hub')
      }, (e) => {this.endpointErrors = e.validation}, () => this.setLoading(false))
    },

    async onSubmit(form) {
      let data = {
        ...form,
        store_id: this.form.store_id.map(store => store.id),
        state_id: this.form.state_id ? this.form.state_id.id : null,
        user_id: this.getUser.id,
        transit_points_type_id: this.isDropoff ? 2 : 1,
        is_active: this.isDropoff ? this.form.is_active : true,
        transit_point_delivery_id: this.transitPointDeliveryId,
        driver_id: this.form?.driver_id?.id,
        
        // Parking
        zip_parking: this.form.zip,
        city_parking: this.form.city,
        neighborhood_parking: this.form.neighborhood,
        address_parking: this.form.address,
        number_parking: this.form.number,
        complement_parking: this.form.complement,
        state_id_parking: this.form.state_id ? this.form.state_id.id : null,
      }
      delete data.undefined
      this.transitPointId ? this.updateTransitPoint(data) : this.createTransitPoint(data)
    },
  }
}
</script>

<style lang="scss" scoped>
.HubControlForm {
 &__content{
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: ". main .";
    grid-column-gap: 1rem;
    margin-bottom: 40px;
 }
 &__form {
    grid-template-areas:
      "labelHub labelHub . . . . . . . . . ."
      ". . . . . . . . . . . ."
      "name name name name name name name route_limit route_limit route_limit schedule schedule"
      "address address address address address address address address address address address address"
      "store_id store_id store_id store_id store_id store_id store_id . . . . ."
      "transit_points_type_id transit_points_type_id transit_points_type_id is_active is_active is_active . . . . . ."
      "transit_point_delivery_id transit_point_delivery_id transit_point_delivery_id driver_id driver_id driver_id reception_user_id reception_user_id reception_user_id route_name route_name route_name"
      ". . . . . . . . . . confirm confirm"
 }
}
</style>
