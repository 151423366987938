export default ({ isDropoff }) => {
  const hubDropoffActive = [
    {
      id: 'is_active',
      model: 'is_active',
      tag: 'BaseCheckBox',
      props: {
        id: 'hub-dropoff-active',
        name: 'Marque caso o dropoff esteja ativo',
        align: 'bottom'
      },
    },
    {
      id: 'transit_point_delivery_id',
      model: 'transit_point_delivery_id',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'Hub de Destino',
        label: 'Hub de Destino',
        placeholder: 'Selecione',
        selectOptions: null,
        fetchOptions: 'stores/fetchHubsAsync',
        invalidLabel: 'Insira um Hub de Destino',
      },
    },
    {
      id: 'driver_id',
      model: 'driver_id',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'Motorista responsável pelo dropoff',
        label: 'Motorista responsável pelo dropoff',
        placeholder: 'Selecione',
        selectOptions: null,
        suggestions: 'searchDrivers',
        invalidLabel: 'Insira um Motorista',
      },
    },
    {
      id: 'reception_user_id',
      model: 'reception_user_id',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'Operador responsável pelo dropoff',
        label: 'Operador responsável pelo dropoff',
        placeholder: 'Selecione',
        selectOptions: null,
        suggestions: 'searchUsers',
        invalidLabel: 'Insira um Motorista',
      },
    },
    {
      id: 'route_name',
      model: 'route_name',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'Nome da rota do dropoff',
        invalidLabel: 'Insira um Nome',
      }
    },
  ]
  return [
    {
      id: 'labelHub',
      tag: 'label',
      content: 'Detalhes do novo HUB'
    },
    {
      id: 'name',
      model: 'name',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'Nome',
        invalidLabel: 'Insira um Nome',
      }
    },
    {
      id: 'address',
      tag: 'InputAddress',
      props: {
        blockFields: false,
        fields: {
          zip: 'zip',
          address: 'address',
          number: 'number',
          complement: 'complement',
          neighborhood: 'neighborhood',
          city: 'city',
          state_id: 'state_id'
        }
      }
    },
    {
      id: 'store_id',
      model: 'store_id',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'Vincular Lojas ao HUB',
        label: 'Vincular Lojas ao HUB',
        placeholder: 'Pesquisar Lojistas',
        multiple: true,
        tags: true,
        persistTags: true,
        searchPromptText: 'Nome da Loja',
        invalidLabel: 'Selecione uma Loja',
        selectOptions: null,
        fetchOptions: 'stores/fetchStoresAsync'
      },
    },
    {
      id: 'transit_points_type_id',
      model: 'transit_points_type_id',
      tag: 'BaseCheckBox',
      props: {
        id: 'hub-dropoff',
        name: 'Marque caso seja um dropoff',
        align: 'bottom'
      },
    },
    ...(isDropoff ? hubDropoffActive : []),
    {
      id: 'confirm',
      tag: 'BaseButton',
      props: {
        label: 'Salvar',
        theme: 'icon-right',
        color: 'primary-base',
        textColor: 'white',
        icon: 'save',
        type: 'submit',
        filled: true
      }
    }
  ]
}